.sectionTwoContent_column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
    align-self: stretch;
}

.sectionTwoContent{
    display: flex;
    flex-direction: row;
    gap: 64px;
    flex-wrap: wrap;
}