.sectionTrheeContent_wrap {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    gap: 32px;
    align-self: stretch;
    flex-wrap: wrap;
}

.sectionTrheeContent{
    display: flex;
    gap: 3rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}