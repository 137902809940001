.heroContent {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    align-self: stretch;
}

.heroContent_cta {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    align-self: center;
    flex-wrap: wrap;
}

.heroContent_Buttons {
    display: flex;
    align-items: center;
    gap: 16px;
}

@media (max-width: 970px){
    .heroContent>h1 {
        text-align: center;
    }
}