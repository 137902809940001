.-fl-s-50{
    fill: var(--color-s-50)
}

.menuButton{
    position: relative;
    display: flex;
    justify-content: flex-end;
    z-index: 20;
}

