.btnPrimary {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    border-radius: 16px;
    background: var(--color-p-900);
    border: none;
    transition: 0.3s ease-in-out;
}
.btnPrimary>p{
    color: var(--color-s-50);
    
}

.btnPrimary>svg{
    fill: var(--color-s-50);
}

.btnPrimary:hover {
    transition: 0.3s ease-in-out;
    transform: scale(1.1);
    background: var(--color-p-50);
}
.btnPrimary:hover > svg {
    transition: 0.3s ease-in-out;
    fill: var(--color-p-900);
}

.btnPrimary:hover > p {
    transition: 0.3s ease-in-out;
    color: var(--color-p-900);
}


.btnSecondary{
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    border-radius: 16px;
    background: var(--color-s-50);
    border: none;
    border-color: none;
}

.btnSecondary>p{
    color: var(--color-p-900);
}

.btnSecondary>svg{
    fill: var(--color-p-900);
}

.btnSecondary:hover {
    transition: 0.3s ease-in-out;
    transform: scale(1.1);
    background: var(--color-p-700);
}
.btnSecondary:hover > svg {
    transition: 0.3s ease-in-out;
    fill: var(--color-s-50);
}

.btnSecondary:hover > p {
    transition: 0.3s ease-in-out;
    color: var(--color-s-50);
}

