.Nav{
    display: flex;
    width: 426px;
    height: 30px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: clamp(0.75rem, 4vw - 2rem, 1rem);
}
.Nav_link{
    text-decoration: none;
}
.-Nav_type{
    color: var(--color-s-50);
    
}
.-Nav_type:hover{
    transition: 0.3s ease-in-out;
    color: var(--color-p-800);
}

.-w7{
    width: 128px;
}


@media (max-width: 970px){
    .Nav{
        position: fixed;
        display: flex;
        z-index: 10;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 95vh;
        gap: 3rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--color-s-900);
    }
    .-w7{
        position: relative;
        z-index: 20;
    }
}
