.sectionFiveContent{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items:center;
    gap: clamp(2rem, 15vw - 1.5rem, 8rem);;
}

.sectionFiveContent_container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    gap: 2rem;
}

.sectionFiveContent_right{
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.sectionFiveContent_left{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
}

@media (max-width: 970px){
    .sectionFiveContent_container{
        flex-direction: column;
        align-items: flex-start;

    }
}