.sectionFourContent {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 128px;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.sectionFourContent_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
}