.typeH1 {
    font-size: clamp(1.5rem, 12vw - 1.25rem, 6.188rem);
	font-family: "Rubik";
	font-weight: 700;
	font-style: normal;
	letter-spacing: -1.5px;
	text-decoration: none;
	text-transform: none;
  margin: 0;
  padding: 0;
}

.typeH2 {
    font-size: clamp(1.5rem, 9vw - 1rem, 4.313rem);
	font-family: "Rubik";
	font-weight: 600;
	font-style: normal;
	letter-spacing: -0.5px;
	text-decoration: none;
	text-transform: none;
  margin: 0;
  padding: 0;
}

.typeH3 {
    font-size: clamp(1.5rem, 4vw + 0.5rem, 3.063rem);
	font-family: "Rubik";
	font-weight: 400;
	font-style: normal;
	text-decoration: none;
	text-transform: none;
  margin: 0;
  padding: 0;
}

.typeH4 {
    font-size: clamp(1.5rem, 2vw + 1rem, 2.188rem);
	font-family: "Rubik";
	font-weight: 400;
	font-style: normal;
	letter-spacing: 0.25px;
	text-decoration: none;
	text-transform: none;
  margin: 0;
  padding: 0;
}

.typeH5 {
    font-size: clamp(0.75rem, 1vw + 0.75rem, 1.563rem);
	font-family: "Rubik";
	font-weight: 400;
	font-style: normal;
	text-decoration: none;
	text-transform: none;
  margin: 0;
  padding: 0;
}

.typeH6 {
    font-size: clamp(0.75rem, 1vw + 0.75rem, 1.313rem);
	font-family: "Rubik";
	font-weight: 500;
	font-style: normal;
	letter-spacing: 0.15px;
	text-decoration: none;
	text-transform: none;
  margin: 0;
  padding: 0;
}

.typeSubtitle1 {
    font-size: clamp(0.75rem, 1vw + 0.75rem, 1rem);
	font-family: "Rubik";
	font-weight: 400;
	font-style: normal;
	letter-spacing: 0.15px;
	text-decoration: none;
	text-transform: none;
  margin: 0;
  padding: 0;
}

.typeSubtitle2 {
    font-size: clamp(0.75rem, 1vw + 0.75rem, 0.875rem);
	font-family: "Rubik";
	font-weight: 500;
	font-style: normal;
	letter-spacing: 0.1px;
	text-decoration: none;
	text-transform: none;
  margin: 0;
  padding: 0;
}

.typeBody1 {
    font-size: clamp(0.75rem, 1vw + 0.75rem, 1rem);
	font-family: "Sora";
	font-weight: 400;
	font-style: normal;
	letter-spacing: 0.5px;
	text-decoration: none;
	text-transform: none;
  margin: 0;
  padding: 0;
}

.typeBody2 {
    font-size: clamp(0.75rem, 1vw + 0.75rem, 0.875rem);
    font-weight: 400;
	font-style: normal;
	letter-spacing: 0.25px;
	text-decoration: none;
	text-transform: none;
  margin: 0;
  padding: 0;

}

.typeButton {
    font-size: clamp(0.75rem, 1vw + 0.75rem, 0.875rem);
	font-family: "Sora";
	font-weight: 400;
	font-style: normal;
	letter-spacing: 1.25px;
	text-decoration: none;
	text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.typeOverline {
  font-size: clamp(0.75rem, 1vw + 0.75rem, 0.75rem);
	font-family: "Sora";
	font-weight: 400;
	font-style: normal;
	letter-spacing: 1.5px;
	text-decoration: none;
	text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.typeCaption {
    font-size: clamp(0.75rem, 1vw + 0.75rem, 0.75rem);
	font-family: "Sora";
	font-weight: 400;
	font-style: normal;
	letter-spacing: 0.4px;
	text-decoration: none;
	text-transform: none;
  margin: 0;
  padding: 0;
}

  .-p900{
    color: var(--color-p-900);
    text-decoration: none;
  }
  
  .-p800 {
    color: var(--color-p-800);
    text-decoration: none;
  }
  
  .-p700 {
    color: var(--color-p-700);
    text-decoration: none;
  }
  
  .-p600 {
    color: var(--color-p-600);
    text-decoration: none;
  }
  
  .-p500 {
    color: var(--color-p-500);
    text-decoration: none;
  }
  
  .-p400 {
    color: var(--color-p-400);
    text-decoration: none;
  }
  
  .-p300 {
    color: var(--color-p-300);
    text-decoration: none;
  }
  
  .-p200 {
    color: var(--color-p-200);
    text-decoration: none;
  }
  
  .-p100 {
    color: var(--color-p-100);
  }
  
  .-p50 {
    color: var(--color-p-50);
    text-decoration: none;
  }
  
  .-p900-50 {
    background: var(--p900-50, linear-gradient(98deg, #123976 0%, #E2EFF7 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .-s900-50 {
    background: var(--color-s-900-50);
    text-decoration: none;
  }
  
  .-s50 {
    color: var(--color-s-50);
    text-decoration: none;
  }
  
  .-s100 {
    color: var(--color-s-100);
    text-decoration: none;
  }
  
  .-s200 {
    color: var(--color-s-200);
    text-decoration: none;
  }
  
  .-s300 {
    color: var(--color-s-300);
    text-decoration: none;
  }
  
  .-s400 {
    color: var(--color-s-400);
    text-decoration: none;
  }
  
  .-s500 {
    color: var(--color-s-500);
    text-decoration: none;
  }
  
  .-s600 {
    color: var(--color-s-600);
    text-decoration: none;
  }
  
  .-s700 {
    color: var(--color-s-700);
    text-decoration: none;
  }
  
  .-s800 {
    color: var(--color-s-800);
    text-decoration: none;
  }
  
  .-s900 {
    color: var(--color-s-900);
    text-decoration: none;
  }