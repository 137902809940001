:root {
	--color-p-900: rgba(18,57,118,1);
	--color-p-800: rgba(31,85,149,1);
	--color-p-700: rgba(38,101,167,1);
	--color-p-600: rgba(46,118,185,1);
	--color-p-500: rgba(53,131,197,1);
	--color-p-400: rgba(77,147,205,1);
	--color-p-300: rgba(104,165,212,1);
	--color-p-200: rgba(143,190,225,1);
	--color-p-100: rgba(185,215,237,1);
	--color-p-50: rgba(226,239,247,1);
	--color-p-900-50: linear-gradient(to bottom, rgba(18,57,118,0.071) 0%,rgba(226,239,247,0.886) 100%);
	--color-s-900-50: radial-gradient(rgba(249,247,255,0.976) 0%,rgba(24,23,29,0.094) 100%);
	--color-s-50: rgba(249,247,255,1);
	--color-s-100: rgba(241,239,248,1);
	--color-s-200: rgba(231,229,238,1);
	--color-s-300: rgba(214,213,221,1);
	--color-s-400: rgba(178,176,185,1);
	--color-s-500: rgba(146,144,152,1);
	--color-s-600: rgba(106,104,112,1);
	--color-s-700: rgba(86,85,92,1);
	--color-s-800: rgba(56,55,62,1);
	--color-s-900: rgba(24,23,29,1);
}

html{
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}
.App{
    width: 100%;
}


body{
	width: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--color-s-900);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}

.scroll-lock {
	overflow: hidden;
  }

::-webkit-scrollbar {
    width: .5rem;
    height: .5rem
}

::-webkit-scrollbar-thumb {
    -webkit-transition: .3s ease all;
    transition: .6s ease all;
    border-color: transparent;
	border-radius: 1rem;
    background-color: var(--color-s-700);
    z-index: 40
}

::-webkit-scrollbar-thumb:hover {
    -webkit-transition: .3s ease all;
    transition: .6s ease all;
	border-radius: 1rem;
    background-color: var(--color-s-800);
}