.phoneVisor {
    width: 329px;
    height: 662px;
}

.phoneVisor_iframe{
    padding: 4%;
    width: 100%;
    height: 100%;
    filter: saturate(0.4) opacity(50%);
    
    
}
.phoneVisor_iframe>iframe{
    width: 100%;
    height: 100%;
    border-radius: 54px;

}