.headerContainer {
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    height: 64px;
    padding: 8px clamp(2rem, 30vw - 5rem, 12.5rem);
    z-index: 10;
    top: 0;
    gap: clamp(0.75rem, 4vw - 2rem, 1rem);
    background: var(--color-s-900);
}

.heroContainer {
    position: relative;
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--color-s-900);


}

.heroContainer_content {
    display: flex;
    padding: 10px 12.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
    flex: 1 0 0;
    align-self: stretch;
    background: radial-gradient(52.04% 52.04% at 50% 50%, rgba(24, 23, 29, 0.00) 23.44%, #18171D 100%);
}

.sectionContainer {
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.sectionContainer_content {
    display: flex;
    padding: 10px 12.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 64px;
    flex: 1 0 0;
    align-self: stretch;
    background: radial-gradient(52.04% 52.04% at 50% 50%, rgba(24, 23, 29, 0.00) 23.44%, #18171D 100%);
}


.cardContainer {
    display: flex;
    padding: 32px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    border-radius: 16px;
    background: rgb(249, 247, 255);
    background: linear-gradient(130deg, rgba(249, 247, 255, 0.1) 0%, rgba(86, 85, 92, 0.2) 20%, rgba(24, 23, 29, 0.4374124649859944) 70%);
    backdrop-filter: blur(6px);
}

.footerContainer {
    display: flex;
    padding: 80px clamp(2rem, 30vw - 5rem, 12.5rem);
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    background: var(--color-s-900);
}



.-w500 {
    flex-basis: clamp(21.875rem, 90vw - 12rem, 31.25rem);
}

@media (max-width: 970px){
    .headerContainer{
        position: sticky;
        display: flex;
        z-index: 20;
        top: 0;
        height: 64px;
        justify-content: space-between;
        background-color: var(--color-s-900);
    }
    .heroContainer_content {
        padding: 2rem;
    }
    .sectionContainer_content{
        padding: 2rem;
    }
}