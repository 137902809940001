.slide-in-elliptic-top-fwd {
    -webkit-animation: slide-in-elliptic-top-fwd 0.640s cubic-bezier(0.79, 0.14, 0.15, 0.86) both;
    animation: slide-in-elliptic-top-fwd 0.640s cubic-bezier(0.79, 0.14, 0.15, 0.86) both;
}

@-webkit-keyframes slide-in-elliptic-top-fwd {
    0% {
        -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
        transform: translateY(-600px) rotateX(-30deg) scale(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        opacity: 0
    }

    100% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% 1400px;
        transform-origin: 50% 1400px;
        opacity: 1
    }
}

@keyframes slide-in-elliptic-top-fwd {
    0% {
        -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
        transform: translateY(-600px) rotateX(-30deg) scale(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        opacity: 0
    }

    100% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% 1400px;
        transform-origin: 50% 1400px;
        opacity: 1
    }
}

.slide-out-elliptic-top-bck {
    -webkit-animation: slide-out-elliptic-top-bck 0.640s cubic-bezier(0.79, 0.14, 0.15, 0.86) both;
    animation: slide-out-elliptic-top-bck 0.640s cubic-bezier(0.79, 0.14, 0.15, 0.86) both;
}

@-webkit-keyframes slide-out-elliptic-top-bck {
    0% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% 1400px;
        transform-origin: 50% 1400px;
        opacity: 1
    }

    100% {
        -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
        transform: translateY(-600px) rotateX(-30deg) scale(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        opacity: 1
    }
}

@keyframes slide-out-elliptic-top-bck {
    0% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% 1400px;
        transform-origin: 50% 1400px;
        opacity: 1
    }

    100% {
        -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
        transform: translateY(-600px) rotateX(-30deg) scale(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        opacity: 1
    }
}

.--up {
    animation: rotate-180-cw 0.640s cubic-bezier(0.79, 0.14, 0.15, 0.86) both;
}

@keyframes rotate-180-cw {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(-180deg);
    }
}

.--down {
    animation: rotate-0-cw 0.640s cubic-bezier(0.79, 0.14, 0.15, 0.86) both;
}

@keyframes rotate-0-cw {
    0% {
        transform: rotate(-180deg);
    }

    100% {
        transform: rotate(0);
    }
}