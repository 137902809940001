.footerContent {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 16px;
}

.footerContent_info{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px;
}

.footerContent_left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: clamp(2rem, 7vw - 0.25rem, 4rem);;
    flex: 1 0 0;
}

.footerContent_contact {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: clamp(2rem, 15vw - 1.5rem, 8rem);
}

.footerContent_right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 32px;
    flex: 1 0 0;
    align-self: stretch;
    flex-wrap: wrap;
    text-align: end;
}
.-w30{
    width: 30%;
}

/* Mobile Version */

@media (max-width: 970px){
    .footerContent{
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
    }
    .footerContent_right{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        flex: 1 0 0;
        align-self: stretch;
        flex-wrap: wrap;
        text-align: start;
    }
    
}