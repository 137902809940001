.sectionOneContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
}

.sectionOneContent_CardsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 32px;
}
.-w-basis{
    flex-basis: clamp(21.875rem, 90vw - 9rem, 43.75rem);;
}